import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner } from "@ionic/react";
import { cardOutline } from "ionicons/icons";
import { useEffect, useState, useContext } from "react";
import AddCardToFile from "./addCardToFile";
import axios from 'axios'
import StandardCenterCenter from "../commonComponents/StandardCenterContainer";
import { AuthContext } from "../auth";

export default function CardOnFile({ onCardUpdate = () => { }, setIsCardOnFile = () => { }, buttonText = 'Update Payment Method', selectedLocationId }) {
    const [cards, setCards] = useState({})
    const [loading, setLoading] = useState(false)
    const { locations, userData } = useContext(AuthContext)

    const getCards = async () => {
        setLoading(true)

        let locationsToConsider = selectedLocationId ? [{ _id: selectedLocationId }] : userData?.locations || []

        console.log(locationsToConsider, 'locationsToConsider===================')

        const cardPromises = locationsToConsider.map(location =>
            axios.get(`/customer_v2/cardStripe`, { params: { locationId: location._id } })
                .catch(error => {
                    console.error(`Failed to fetch card for location ${location._id}:`, error);
                    return { data: null };
                })
        )
        const responses = await Promise.all(cardPromises)
        const cardData = responses.reduce((acc, response, index) => {
            if (response.data !== null) {
                acc[locationsToConsider[index]._id] = response.data
            }
            return acc
        }, {})
        setCards(cardData)
        let isCardOnFile = Object.values(cardData).some(card => card?.cardOnFile) || false
        console.log(isCardOnFile, 'isCardOnFile===================')
        setIsCardOnFile(isCardOnFile)

        setLoading(false)
    }

    useEffect(() => { getCards() }, [userData])


    console.log(cards)
    return (

        <>
            {selectedLocationId ?
                <>
                    <CardOnFileLocation location={locations.find(loc => loc._id === selectedLocationId)} cards={cards} loading={loading} userData={userData} onCardUpdate={onCardUpdate} setIsCardOnFile={setIsCardOnFile} buttonText={buttonText} getCards={getCards} selectedLocationId={selectedLocationId} />
                </>
                :
                locations.map(location => <CardOnFileLocation location={location} cards={cards} loading={loading} userData={userData} onCardUpdate={onCardUpdate} setIsCardOnFile={setIsCardOnFile} buttonText={buttonText} getCards={getCards} selectedLocationId={selectedLocationId} />)
            }


            <StandardCenterCenter>
                <img src='/assets/poweredByStripe_black.svg' alt="" style={{ height: 22, marginTop: 10, marginBottom: 20 }} />
            </StandardCenterCenter>
        </>
    )
}

function capitalizeFirstLetter(str) {
    if (!str) {
        return '';
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}


function CardOnFileLocation({ location, cards, loading, userData, buttonText, onCardUpdate, setIsCardOnFile, getCards, selectedLocationId }) {
    const [showCardInput, setShowCardInput] = useState(false)
    if (selectedLocationId !== location._id) {
        if (!userData?.locations?.some(loc => loc._id === location._id)) {
            return null
        }
    }

    function handleCardUpdate() {
        getCards()
        setIsCardOnFile(true)
        setShowCardInput(false)
        onCardUpdate()
    }

    console.log(cards)

    return (
        <>
            <IonGrid key={location._id}>
                <IonRow>
                    {selectedLocationId ?
                        null :
                        <IonCol size='12'>
                            <h4>{location.name}</h4>
                        </IonCol>
                    }
                    <IonCol size='auto'>
                        <div style={{ height: 50, width: 50, borderRadius: 25, marginLeft: 10, marginRight: 10, backgroundColor: '#323232', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <IonIcon icon={cardOutline} style={{ color: 'white' }} size='large' />
                        </div>
                    </IonCol>
                    <IonCol>
                        <div style={{ height: 50, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {loading ? <IonSpinner name='dots' /> :
                                <> {!cards[location._id]?.cardOnFile ?
                                    <>
                                        Add a Default Card for {location.name}
                                    </>
                                    :
                                    <>
                                        <span style={{ fontWeight: '500', color: '#313131' }}>{capitalizeFirstLetter(cards[location._id]?.cardDetails?.cardBrand)}</span>
                                        <span>Ending in {cards[location._id]?.cardDetails?.cardLast4}</span>
                                    </>
                                }
                                </>
                            }
                        </div>
                    </IonCol>
                    {cards[location._id]?.cardOnFile && (
                        <IonCol size='auto'>
                            <div style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                                <IonButton fill="clear" onClick={() => {
                                    setShowCardInput(!showCardInput)
                                }}>
                                    {showCardInput ? "Hide" : "Update"}
                                </IonButton>
                            </div>
                        </IonCol>
                    )}
                </IonRow>
                {(showCardInput || !cards[location._id]?.cardOnFile) && (
                    <IonRow>
                        <IonCol>
                            {location?.stripeAPIPublicKey ?
                                <AddCardToFile
                                    buttonText={buttonText}
                                    addCardToFileSuccess={handleCardUpdate}
                                    location={location}
                                    stripeAPIPublicKey={location?.stripeAPIPublicKey}
                                />
                                : null
                            }
                        </IonCol>
                    </IonRow>
                )}
            </IonGrid>
        </>

    )
}
