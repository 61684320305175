import { useContext, useEffect, useState } from 'react'
import { IonPage, IonContent, IonImg, IonLabel, IonGrid, IonRow, IonButton, IonCol, IonIcon } from '@ionic/react'
import { useHistory } from 'react-router'
import axios from 'axios'
import { fontSizes } from '../../theme/typography'
import StandardContainer from '../../commonComponents/standardContainer'
import Map from './map'
import { isLocationOpen } from '../../utils'
import { chevronBack } from 'ionicons/icons'
const LocationDetails = ({ match, history }) => {
  return (
    <IonPage>
      <IonContent className='regularBackground'>
        <BodyContent id={match.params.id} />
      </IonContent>
    </IonPage>
  )
}

const BodyContent = ({ id }) => {
  const [data, setData] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    getData()
  }, [id])

  async function getData() {
    try {
      setError(false)
      let d = await axios.get(`/location_v2/publicGet?locationId=${id}`)
      setData(d.data)
      setLoading(false)
    } catch (error) {
      setError(true)
    }
  }
  function HoursOfOperation(data) {
    let schedule = data?.standardSchedule

    if (!schedule) return null

    return (
      <IonGrid style={{ marginBottom: 10, color: 'black' }}>
        <IonRow>
          <IonCol style={{ textAlign: 'left' }}>
            <h6 style={{ marginTop: 0 }}>Hours of Operation</h6>
            <p> {isLocationOpen(data)}</p>
          </IonCol>
        </IonRow>
        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((key) => (
          <IonRow key={key}>
            <IonCol></IonCol>
            <IonCol size='auto' style={{ textAlign: 'left' }}>
              <div style={{ minWidth: 130, fontWeight: '600' }}>{key}</div>
            </IonCol>
            <IonCol size='auto'>
              <div style={{ minWidth: 160, textAlign: 'left' }}>
                {schedule[key]?.enabled ? (
                  <>
                    {schedule[key]?.startTime} - {schedule[key]?.endTime}
                  </>
                ) : (
                  'Closed'
                )}
              </div>
            </IonCol>
            {/* <IonCol size='auto'>
							-
						</IonCol>
						<IonCol size='auto'>
							{schedule[key]?.startTime}
						</IonCol> */}
            <IonCol></IonCol>
          </IonRow>
        ))}
      </IonGrid>
    )
  }
  const openMaps = () => {
    const latitude = data?.latitude;
    const longitude = data?.longitude;
    const label = encodeURIComponent(data?.name);

    // Check if the device is iOS
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.open(`maps://maps.apple.com/?q=${latitude},${longitude}&ll=${latitude},${longitude}&t=m`, '_blank');
    }
    // Check if the device is Android
    else if (/Android/.test(navigator.userAgent)) {
      window.open(`geo:${latitude},${longitude}?q=${latitude},${longitude}(${label})`, '_blank');
    }
    // For all other devices, default to Google Maps
    else {
      window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
    }
  }
  const bookAppointment = (locationId) => {
    history.push('/schedule/')
  }
  if (loading) return <div>Loading...</div>
  if (error) return <div>error</div>

  return (
    <>
      <div>
        <div style={{ position: 'relative' }}>
          {data?.coverPhotoURL ? (
            <div
              onClick={() => history.goBack()}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', position: 'absolute', left: 10, top: 10, color: 'white' }}
            >
              <IonIcon icon={chevronBack} />
              <IonLabel>Back</IonLabel>
            </div>
          ) : (
            <div onClick={() => history.goBack()} style={{ marginTop: 20, marginLeft: 10, cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <IonIcon icon={chevronBack} />
              <IonLabel>Back</IonLabel>
            </div>
          )}
          {data?.coverPhotoURL && (
            <IonImg
              style={{ width: '100%', height: '100%', backgroundSize: 'cover' }}
              src={data?.coverPhotoURL}
            ></IonImg>
          )}
        </div>
        <StandardContainer>
          <IonGrid style={{ padding: 0 }}>
            <IonRow style={{ marginBottom: 20 }}>
              <IonLabel style={{ fontSize: fontSizes.size30 }}>{data.name}</IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel style={{ display: 'block' }}>{data?.addressStreet1}</IonLabel>
            </IonRow>
            {data?.addressStreet2 ? <IonRow><IonLabel style={{ display: 'block' }}>{data?.addressStreet2}</IonLabel></IonRow> : null}
            <IonRow>
              <IonLabel>
                {data?.addressCity}, {data?.addressState} {data?.addressZipcode}
              </IonLabel>
            </IonRow>
            <IonRow style={{ marginBottom: 20 }}>
              <IonLabel>{data?.phoneNumber}</IonLabel>
            </IonRow>

            <IonRow style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <IonButton onClick={() => bookAppointment(data?._id)} style={{ width: '48%' }} color={'dark'} expand='block'>
                Book
              </IonButton>
              {data?.latitude && data?.longitude ? (
                <IonButton onClick={openMaps} style={{ width: '48%' }} fill='outline' color={'dark'} expand='block'>
                  Directions
                </IonButton>
              ) : null}
            </IonRow>
            {data?.latitude && data?.longitude ? (
              <div style={{ height: 250, borderRadius: 20, marginTop: 20, width: '100%' }}>
                <div style={{ marginBottom: -80 }}>
                  <Map style={{ borderRadius: 5 }} latitude={data?.latitude} longitude={data?.longitude} />
                </div>
              </div>
            ) : null}
            <IonRow>{HoursOfOperation(data)}</IonRow>
          </IonGrid>
        </StandardContainer>
      </div>
    </>
  )
}

export default LocationDetails
