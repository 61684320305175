import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'

export const getFormattedDate = (date) => {
  var d = new Date(date)
  var minutes = d.getMinutes().toString().length == 1 ? '0' + d.getMinutes() : d.getMinutes(),
    hours = d.getHours().toString().length == 1 ? '0' + d.getHours() : d.getHours(),
    ampm = d.getHours() >= 12 ? 'pm' : 'am',
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ' ' + hours + ':' + minutes + ampm
}

export const getFormattedMediumDate = (date) => {
  var d = new Date(date)
  var options = { timeZone: 'America/Denver', month: 'short', day: 'numeric', year: 'numeric' }
  return d.toLocaleString('en-US', options)
}
export const getFormattedMediumDateWithoutTimeZone = (date) => {
  const formattedDate = dayjs(date).format('MMM D, YYYY')
  return formattedDate
}
export const formatDateWithWeekDay = (date) => {
  const options = { weekday: 'short', month: 'long', day: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}
export const getFormattedSmallDate = (date) => {
  var d = new Date(date)
  var options = { timeZone: 'America/Denver', month: 'long', day: 'numeric' }
  return d.toLocaleString('en-US', options)
}

export const getWeekday = (date) => {
  let d = new Date(date)
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return days[d.getDay()]
}

export const getShortWeekday = (date) => {
  let d = new Date(date)
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return days[d.getDay()]
}

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export const stringToColor = (string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor, darkColor) => {
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  var uicolors = [r / 255, g / 255, b / 255]
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? darkColor : lightColor
}

export const setTimeToDate = (inputDate, timeString) => {
  let d = inputDate ? new Date(inputDate) : new Date(),
    s = timeString,
    parts = s.match(/(\d+)\:(\d+) (\w+)/),
    hours = /am/i.test(parts[3].toLowerCase()) || parseInt(parts[1], 10) == 12 ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
    minutes = parseInt(parts[2], 10)

  d.setHours(hours)
  d.setMinutes(minutes)
  d.setSeconds(0)
  d.setMilliseconds(0)

  return d
}

export const isLocationOpen = (location) => {
  if (!location) return null
  // Get current date and time
  let currentDay = new Date().getDay()
  let currentTime = new Date().toLocaleTimeString('en-US', { hour12: false })

  // Map the day index (0-6) to the day names
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  let currentDayName = dayNames[currentDay]

  // Get the opening hours for the current day
  let scheduleForToday = location.modifiedScheduleForToday || location?.standardSchedule?.[currentDayName]

  // Check if the business is open today
  if (scheduleForToday?.enabled) {
    let start = new Date()
    let end = new Date()
    let [startHours, startMinutes] = scheduleForToday?.startTime?.split(' ')[0].split(':')
    let [endHours, endMinutes] = scheduleForToday?.endTime?.split(' ')[0].split(':')

    // Convert the opening hours to 24 hours format
    if (scheduleForToday?.startTime?.includes('PM') && +startHours !== 12) startHours = +startHours + 12
    if (scheduleForToday?.endTime?.includes('PM') && +endHours !== 12) endHours = +endHours + 12

    start.setHours(startHours, startMinutes)
    end.setHours(endHours, endMinutes)

    // Check if the current time is before the opening hours
    if (new Date() < start) {
      return `Opens at ${scheduleForToday.startTime}`
    }
    // Check if the current time is within the opening hours
    else if (new Date() >= start && new Date() <= end) {
      return 'Open Now'
    } else {
      return 'Closed'
    }
  } else {
    return 'Closed Today'
  }
}

export const calculateCostPerMonth = (amount, interval, intervalCount) => {
  switch (interval) {
    case 'day':
      return amount * 30
    case 'week':
      return amount / 7
    case 'month':
      return parseFloat(amount) / parseFloat(intervalCount)
    case 'year':
      return amount / 12
    default:
      return null
  }
}

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

// export function getReferralSource() {
//     const query = useQuery();
//     return query.get('ref_source')
// }

export const calculateNearestFutureSubscriptionEndDate = (startDate, period) => {
  // Convert start date string to a Date object
  period = period.replace(/\s+/g, ' ')

  const startDateObj = new Date(startDate)
  const currentDate = new Date()
  // Set time parts of startDateObj and currentDate to 0 to compare only date, month, and year
  startDateObj.setHours(0, 0, 0, 0)
  currentDate.setHours(0, 0, 0, 0)
  console.log(startDateObj, 'startDateObj')
  console.log(currentDate, 'currentDate')
  // If the start date is in the past, find the next future date
  if (startDateObj <= currentDate) {
    while (startDateObj <= currentDate) {
      // Define period mapping for each option
      const periodMap = {
        'every day': (date) => date.setDate(date.getDate() + 1),
        'every week': (date) => date.setDate(date.getDate() + 7),
        'every month': (date) => date.setMonth(date.getMonth() + 1),
        'every year': (date) => date.setFullYear(date.getFullYear() + 1),
        monthly: (date) => date.setMonth(date.getMonth() + 1),
        weekly: (date) => date.setDate(date.getDate() + 7),
        daily: (date) => date.setDate(date.getDate() + 1),
        annually: (date) => date.setFullYear(date.getFullYear() + 1),
        yearly: (date) => date.setFullYear(date.getFullYear() + 1),
        '6 months': (date) => date.setMonth(date.getMonth() + 6),
        quarterly: (date) => date.setMonth(date.getMonth() + 3),
      }

      // Check if the provided period is in the predefined periodMap
      if (period in periodMap) {
        periodMap[period](startDateObj)
      } else {
        const [frequency, unit] = period?.split(' ').filter((part) => part !== 'every')
        // Adjust the start date based on the period
        const unitWithoutS = unit.endsWith('s') ? unit.slice(0, -1) : unit

        let finalFrequency = frequency == '' ? 1 : frequency
        switch (unitWithoutS) {
          case 'day':
            startDateObj.setDate(startDateObj.getDate() + parseInt(finalFrequency))
            break
          case 'week':
            startDateObj.setDate(startDateObj.getDate() + parseInt(finalFrequency) * 7)
            break
          case 'month':
            startDateObj.setMonth(startDateObj.getMonth() + parseInt(finalFrequency))
            break
          case 'year':
            startDateObj.setFullYear(startDateObj.getFullYear() + parseInt(finalFrequency))
            break
          default:
            return 'Invalid period'
        }
      }
    }
  }

  return getFormattedMediumDate(startDateObj)
}
export const timeSince = (timeStamp) => {
  const now = new Date()
  const secondsPast = (now.getTime() - timeStamp) / 1000
  if (secondsPast < 60) {
    return `${Math.round(secondsPast)}s ago`
  }
  if (secondsPast < 3600) {
    return `${Math.round(secondsPast / 60)}m ago`
  }
  if (secondsPast <= 86400) {
    return `${Math.round(secondsPast / 3600)}h ago`
  }
  if (secondsPast > 86400) {
    const day = timeStamp.getDate()
    const month = timeStamp
      .toDateString()
      .match(/ [a-zA-Z]*/)[0]
      .replace(' ', '')
    const year = timeStamp.getFullYear() === now.getFullYear() ? '' : ` ${timeStamp.getFullYear()}`
    return `${day} ${month}${year}`
  }
}
export const limitDecimalPlaces = (value, decimalPlaces) => {
  const stringValue = value?.toString()
  const decimalIndex = stringValue?.indexOf('.')

  if (decimalIndex !== -1) {
    // If there is a decimal point
    const decimalPart = stringValue?.substring(decimalIndex + 1, stringValue?.length)
    return decimalPart?.length > decimalPlaces ? parseFloat(stringValue)?.toFixed(decimalPlaces) : value
  }

  return value
}
export const formatDateToISOString = (date) => {
  const padToTwoDigits = (num) => String(num).padStart(2, '0')

  // Format the date as 'YYYY-MM-DDTHH:mm:ss'
  const formattedDateWithTime = `${date.getFullYear()}-${padToTwoDigits(date.getMonth() + 1)}-${padToTwoDigits(date.getDate())}T${padToTwoDigits(
    date.getHours()
  )}:${padToTwoDigits(date.getMinutes())}:${padToTwoDigits(date.getSeconds())}`
  return formattedDateWithTime
}
