import { Capacitor } from "@capacitor/core"
import axios from "axios"

export const trackError = (category, message, code) => {
    try {
        axios.post('/analytics', {
            eventName: "error",
            category,
            message,
            code
        })
    } catch (error) {

    }

}

export const trackScheduling = (stepName, step, scheduleData, isRepeatStep, email) => {
    try {
        axios.post('/analytics', {
            eventName: "scheduling",
            stepName,
            step,
            isLoggedIn: scheduleData.customer ? true : false,
            isWalkin: scheduleData.isWalkin ? true : false,
            isRepeatStep,
            wasLoggedInAtStart: scheduleData.wasLoggedInAtStart,
            platform: Capacitor.getPlatform()
        })
    } catch (error) {

    }

    try {
        // if (window.gtag) {
        //     window.gtag(
        //         'event',
        //         `scheduling_${stepName}`,
        //         {
        //             'event_category': 'Scheduling',
        //             'event_label': stepName
        //         })
        // }

        let emailData ={}
        if (email) {
            emailData = {
                'customer_email': email
            }
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': `scheduling_${stepName}`,
            ...email
        });
    } catch (error) {
        console.log(error)
    }

}

export const trackCreatedAccount = () => {
    try {
        axios.post('/analytics', {
            eventName: "accountCreated",
            platform: Capacitor.getPlatform()
        })
    } catch (error) {

    }

    try {
        // if (window.gtag) {
        //     window.gtag(
        //         'event',
        //         `AccountCreated`,
        //         {
        //             'event_category': 'AccountCreated',
        //             'event_label': "AccountCreated"
        //         }
        //     )
        // }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'AccountCreated'
        });
    } catch (error) {
        console.log(error)
    }

}

export const trackSubscribed = (referrer, version) => {
    try {
        axios.post('/analytics', {
            eventName: "subscribed",
            referrer,
            promotionVersion: version,
            platform: Capacitor.getPlatform()
        })
    } catch (error) {

    }

    try {
        // if (window.gtag) {
        //     window.gtag(
        //         'event',
        //         `Subscribed`,
        //         {
        //             'event_category': 'Subscribed',
        //             'event_label': "Subscribed"
        //         }
        //     )
        // }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Subscribed'
        });
        
    } catch (error) {
        console.log(error)
    }

}

export const trackPromotionImpression = (location, promotionVersion) => {
    try {
        axios.post('/analytics', {
            eventName: "promotionImpression",
            location,
            promotionVersion,
            platform: Capacitor.getPlatform()
        })
    } catch (error) { }

}

export const trackPageEvent = (name) => {
    try {
        axios.post('/analytics', {
            eventName: "pageView",
            pageName: name,
            platform: Capacitor.getPlatform()
        })
    } catch (error) {

    }

    try {
        if (window.gtag) {
            window.gtag(
                'event',
                `PageView_${name}`,
                {
                    'event_category': 'PageView',
                    'event_label': name
                }
            )
        }
    } catch (error) {
        console.log(error)
    }

}