import { IonContent, IonGrid, IonLabel, IonPage, IonRow, IonImg } from '@ionic/react'
import StandardContainer from '../../commonComponents/standardContainer'
import { fontSizes, fontWeights } from '../../theme/typography'
import { useHistory } from 'react-router'
const Details = ({ item }) => {
  return (
    <>
      <IonLabel
        style={{
          fontSize: fontSizes.size20,
          fontWeight: fontWeights.weightBold,
          marginBottom: '10px',
          display: 'block',
        }}
      >
        {item.name}
      </IonLabel>

      <IonLabel style={{ display: 'block' }}>{item?.addressStreet1}</IonLabel>
      {item?.addressStreet2 ? <IonLabel style={{ display: 'block' }}>{item?.addressStreet2}</IonLabel> : null}
      <IonLabel>
        {item?.addressCity}, {item?.addressState} {item?.addressZipcode}
      </IonLabel>

      <IonLabel>{item?.phoneNumber}</IonLabel>
    </>
  )
}
export default function LocationsList({ locations }) {
  const history = useHistory()

  const navigateToLocation = (id) => {
    history.push(`/location/${id}`)
  }

  return (
    <IonPage>
      <IonContent className='whiteBackground'>
        <StandardContainer>
          <div style={{ marginTop: 20 }}>
            <IonLabel style={{ fontSize: fontSizes.size30 }}>Locations</IonLabel>
          </div>
          <IonGrid gap={10} style={{ padding: 0, gap: 10 }}>
            {locations?.map((item, index) => {
              return (
                <IonRow
                  key={index}
                  onClick={() => navigateToLocation(item._id)}
                  style={{
                    cursor: 'pointer',
                    marginTop: 20,
                    border: '2px solid grey',
                    borderRadius: 10,
                    padding: 0,
                    overflow: 'hidden',
                    position: 'relative',
                    height: item.coverPhotoURL ? '300px' : '',
                    width: '100%',
                  }}
                >
                  {item.coverPhotoURL ? (
                    <>
                      <IonImg
                        src={item.coverPhotoURL} // Assuming you have 'imageUrl' for each location
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 0,
                        }}
                      />

                      {/* Overlay for the fade effect */}
                      <div
                        style={{
                          position: ' absolute',
                          bottom: ' 0px',
                          left: ' 0px',
                          right: ' 0px',
                          padding: ' 20px',
                          background: ' linear-gradient(1deg, #ffffff, transparent)',
                          color: ' black',
                          zIndex: ' 1',
                          height: ' 80%',
                        }}
                      >
                        <div style={{ position: 'absolute', bottom: 20 }}>
                          <Details item={item} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          padding: ' 20px',
                          color: ' black',
                          zIndex: ' 1',
                        }}
                      >
                        <div>
                          <Details item={item} />
                        </div>
                      </div>
                    </>
                  )}
                </IonRow>
              )
            })}
          </IonGrid>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
