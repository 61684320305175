import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import StepPickBarber from './stepPickBarber'
import StepPickLocation from './stepPickLocation'
import StepPickDateTime from './stepPickDateTime'
import StepPickService from './stepPickService'
import StepSuccess from './stepSuccess'
import StepReviewOrder from './reviewOrder'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonSpinner } from '@ionic/react'
import StepLoginOrRegister from './stepLoginOrRegister'
import HeaderComponent from './headerComponent'
import { AuthContext } from '../auth'
import { calculatePayment } from '../sharedComponents/paymentCalculation'
import StandardContainer from '../commonComponents/standardContainer'
import { trackScheduling } from '../analytics'
import StepCancellation from './StepCancellation'
import { chevronBack, closeOutline } from 'ionicons/icons'

const HEADER_TEXT = {
  service: 'Pick a Service',
  dateTime: 'Pick a Time',
  barber: 'Pick a Professional',
  loginOrRegister: 'Login or Register',
  summary: 'Review Appointment',
  success: 'Thanks for Booking!',
  location: 'Pick a Location',
}

export default function StepController({ locationToBook, closeModal, getAppointments, isWalkin, setSubscriptionReferrer }) {
  const { userData, locations, businessId } = useContext(AuthContext)
  const [step, setStep] = useState(locationToBook && locations.length > 1 ? 1 : 0)
  const [steps, setSteps] = useState()
  const [customer, setCustomer] = useState(null)
  const [stepBackData, setStepBackData] = useState(null)
  const [trackedSteps, setTrackedSteps] = useState([])
  const [coreAppointment, setCoreAppointment] = useState({
    businessId: businessId,
    barber: null,
    anyProfessional: isWalkin ? true : false,
    isWalkin: isWalkin ? true : false,
    services: [],
    dateTime: null,
    payment: {
      subTotalPrice: 0,
      totalPrice: 0,
      tipAmount: 0,
      tipPercentage: 0,
      taxes: 0,
      taxRate: 0,
      productsTotal: 0,
      servicesTotal: 0,
      totalDiscount: 0,
    },
    durationMin: null,
    wasLoggedInAtStart: userData?._id ? true : false,
  })

  //Detects when the user is logged in
  useEffect(() => {
    if (userData && userData != 'loading') {
      setCustomer({ _id: userData._id })
    }
  }, [userData])

  //Sets the steps based on if the user is walkin or not
  useEffect(() => {
    //Walkin
    if (isWalkin) {
      let steps = []
      if (locations.length > 1) {
        steps.push('location')
      }
      steps.push(...['service', 'summary', 'success'])
      setSteps(steps)
    //Appointment 
    } else {
      let baseSteps = []
      if (locations.length > 1) {
        baseSteps.push('location')
      }
      baseSteps.push(...['barber', 'service', 'dateTime'])
      baseSteps.push(...['cancellationPolicy', 'summary', 'success'])
      setSteps(baseSteps)
    }
  }, [locations])

  //Get the Location Data when the locationId changes
  useEffect(() => {
    console.log(("get the location"))
    if (locationToBook) {
      updateScheduleData('location', locations?.find((item, index) => item._id == locationToBook))
    }
  }, [ locationToBook])

  useEffect(()=>{
    if(locationToBook) return 

    if (locations.length === 1) {
      updateScheduleData('location', locations?.[0])
    }
  },[locations])

  function trackStep(stepName, step, data) {
    trackScheduling(stepName, step, data, trackedSteps.includes(stepName) ? true : false, userData?.email)
    setTrackedSteps([...trackedSteps, ...[stepName]])
  }

  function stepNext() {
    if (step + 1 >= steps.length) {
      closeModal()
    } else {
      setStep(step + 1)
    }
  }

  function stepBack() {
    if (step == 0) {
      closeModal()
    } else {
      if (stepBackData) {
        setCoreAppointment(stepBackData)
      }

      setStep(step - 1)
    }
  }
  const handleChangeStep = (stepName) => {
    const index = steps.indexOf(stepName)
    setStep(index)
  }



  function handleCancel() {
    closeModal()
  }

  function updateScheduleData(key, value) {
    setStepBackData(null)
    let updatedData = { ...coreAppointment }
    updatedData[key] = value

    if (key == 'dateTime') {
      updatedData.barber = value.barber
    }

    if (key == 'dateTimeAnyProfessional') {
      updatedData.barber = value.barber
      updatedData['requestedBarber'] = null
      updatedData.anyProfessional = true
      delete value.barber
      updatedData['dateTime'] = value
      setStepBackData(coreAppointment)
    }

    if (key == 'barber' && value == 'Anyone') {
      updatedData[key] = null
      updatedData['requestedBarber'] = null
      updatedData.anyProfessional = true
    } else if (key == 'barber') {
      updatedData['requestedBarber'] = value
      updatedData.anyProfessional = false
    }

    //if Schedule Update
    if (key == 'services') {
      let duration = 0
      for (let s of updatedData?.services) {
        duration += s.durationMin
      }
      updatedData.durationMin = duration

      updatedData.payment = calculatePayment(updatedData?.services, updatedData?.products, userData?.subscription?.subscriptionPackageId, null, null, null)
    }

    if(key == 'location'){
      updatedData.location = locations?.find((item, index) => item._id == value._id)
      updatedData.services = []
    }

    setCoreAppointment(updatedData)
  }

  function track() {}

  let scheduleData = { 
    ...coreAppointment, 
    ...{ customer }
  }


  let stepComponent = null
  let stepName = locations?.length < 1 ? 'loading' : (steps && steps[step]) || null
  console.log(stepName, 'stepName')
  console.log(scheduleData, 'scheduleData')
  if (stepName == 'barber') {
    stepComponent = (
      <StepPickBarber
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'location') {
    stepComponent = (
      <div>
        <div>
          <StepPickLocation
            locations={locations}
            businessId={businessId}
            scheduleData={scheduleData}
            updateScheduleData={updateScheduleData}
            step={step}
            stepBack={stepBack}
            stepNext={stepNext}
            totalSteps={(steps && steps.length) || 0}
            trackStep={trackStep}
          />
        </div>
      </div>
    )
  } else if (stepName == 'service') {
    stepComponent = (
      <StepPickService
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        userSubscription={userData?.subscription}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'dateTime') {
    stepComponent = (
      <StepPickDateTime
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'cancellationPolicy') {
    if (!userData?._id) {
      stepComponent = (
        <span>
          <div>
            <StepLoginOrRegister
              businessId={businessId}
              scheduleData={scheduleData}
              updateScheduleData={updateScheduleData}
              step={step}
              stepBack={stepBack}
              stepNext={stepNext}
              totalSteps={(steps && steps.length) || 0}
              trackStep={trackStep}
            />
          </div>
        </span>
      )
    } else {
      stepComponent = (
        <StepCancellation
          businessId={businessId}
          scheduleData={scheduleData}
          updateScheduleData={updateScheduleData}
          step={step}
          stepBack={stepBack}
          stepNext={stepNext}
          totalSteps={(steps && steps.length) || 0}
          isWalkin={isWalkin}
          trackStep={trackStep}
        />
      )
    }
  } else if (stepName == 'summary') {
    stepComponent = (
      <div>
        <div>
          <StepReviewOrder
            setStep={handleChangeStep}
            isWalkin={isWalkin}
            userSubscription={userData?.subscription}
            businessId={businessId}
            scheduleData={scheduleData}
            updateScheduleData={updateScheduleData}
            step={step}
            stepBack={stepBack}
            stepNext={stepNext}
            totalSteps={(steps && steps.length) || 0}
            trackStep={trackStep}
          />
        </div>
      </div>
    )
  } else if (stepName == 'success') {
    stepComponent = (
      <div>
        <StepSuccess
          businessId={businessId}
          isSubscribed={userData?.isSubscribed}
          scheduleData={scheduleData}
          updateScheduleData={updateScheduleData}
          step={step}
          stepBack={stepBack}
          stepNext={stepNext}
          totalSteps={(steps && steps.length) || 0}
          getAppointments={getAppointments}
          isWalkin={isWalkin}
          trackStep={trackStep}
          setSubscriptionReferrer={setSubscriptionReferrer}
        />
      </div>
    )
  } else {
    stepComponent = (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <IonSpinner name='dots' />
      </div>
    )
  }

  return (
    <>
      {stepName == 'success' ? null : (
        <IonHeader style={{ paddingTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white' }}>
          {stepName == 'success' || step == 0 ? <div style={{width: 30, height: 32}}/> : (
            <IonButtons slot='start'>
              <IonButton onClick={stepBack} style={{ color: 'black' }}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
          )}

          <IonButtons slot='end' style={{ marginRight: 20, color: 'black' }}>
            <IonIcon onClick={handleCancel} style={{ height: 30, width: 30 }} icon={closeOutline} />
            {/* <IonButton onClick={handleCancel}>Cancel</IonButton> */}
          </IonButtons>
        </IonHeader>
      )}
      <IonContent fullscreen={true} className='whiteBackground'>
        <StandardContainer padding={0}>
          <HeaderComponent handleBack={stepBack} progress={{ total: (steps && steps.length) || 1, step: (steps && step + 1) || 0 }} />
          {stepComponent}
        </StandardContainer>
      </IonContent>
    </>
  )
}
